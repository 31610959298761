import useBaseUtils from "~/functions/useBaseUtils"


export default {
  __name: 'DiligenceLockedMessage',
  props: {
  label: {
    type: String,
    default: "Data is only available to Plus and Pro users",
  },
  wrapperClass: {
    type: String,
    default: "text-center font-weight-medium",
  },
  buttonWrapperClass: {
    type: String,
    default: "text-center mt-3",
  },
  showButton: {
    type: Boolean,
    default: true,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



const { I18nFn, refCode } = useBaseUtils()

return { __sfc: true,I18nFn, refCode }
}

}